<template>
  <div class="help-detail">
    <div class="nav-title">
      <i></i>首页—使用帮助
    </div>
    <div class="content">
      <div class="title">
        <div>
          <div v-for="(item, index) in detailData" @click="handleTab(index)">
            <span :class="[{'active' : tabIndex == index }]">{{item.title}}</span>
          </div>
        </div>

        <div>
          <span class="button-primary" @click="onBack">返回</span>
        </div>
      </div>
      <div class="list" v-if="detailData.length">
        <div class="ql-editor" v-html="detailData[tabIndex].content" />
      </div>
    </div>

    <a-back-top :target="targetFn">
      <div class="ant-back-top-inner">
        <a-icon type="vertical-align-top" />
      </div>
    </a-back-top>
  </div>
</template>

<script>
import { helpContent } from '@/api/announce.js'

export default {
  components: { },
  data() {
    return {
      detailData: {},
      tabIndex: 0,
      type: ''
    }
  },
  watch: {
    $route() {
      this.$router.go(0)
    },
  },
  mounted() {
    this.type = this.$route.query.type
    this.onHelp()
  },
  methods: {
    targetFn() {
      return document.getElementById('app')
    },
    handleTab(index) {
      this.tabIndex = index
    },
    // 帮助详情
    async onHelp() {
      const res = await helpContent()
      this.detailData = res.data

      res.data.map((item, index) => {
        if (item.title == this.type) {
          this.tabIndex = index
        }
      })
    },
    onBack() {
      this.$router.go(-1)
    }
  }
};
</script>

<style lang="less">
@import "../../styles/quill.bubble.css";
@import "../../styles/quill.core.css";
@import "../../styles/quill.snow.css";

.help-detail {
  width: 100%;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .content {
    width: auto;
    padding: 0px 51px;

    .title {
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
      }
      .button-primary {
        height: 34px !important;
        margin-right: 0;
        color: #6868CE;
      }

      & > div > div > span  {
        font-size: 16px;
        font-weight: 500;
        color: #4A4A4A;
        margin-right: 36px;
        height: 45px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.active {
          color: #6868ce;
          border-bottom: 5px solid #6868ce;
        }
      }
    }

    .list {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      margin-top: 10px;
      padding: 0px 26px 16px 26px;
      box-sizing: border-box;
      padding-top: 29px;
      min-height: 60vh;
      img {
        max-width: 100%;
      }
    }
  }

  .ant-back-top-inner {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: #6868ce;
    color: #fff;
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
